import React from 'react'

function About() {
    return (
        <>
            <div className="home-headline">
                <h1>Kontakt</h1>
            </div>
            <div className="about--container">
                <ul>
                    <li><h1>Email:  JMTraefaeldning@gmail.com </h1></li><br />
                    <li><h1>Tlf:  22289789 </h1></li><br />
                    <li><h1>CVR:  40691448 </h1></li><br />
                    <li><h1>Adresse:  Blåmejsevej 27,  2600 Glostrup </h1></li><br />
                </ul>
            </div>
        </>
    )
}

export default About